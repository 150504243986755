import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import constants from '@/constants/static.json'
import { paginateArray } from '@/constants/utils'

export default function useCompanyList() {
  // Use toast
  const { PER_PAGE_OPTIONS, PER_PAGE } = constants
  const refCompanyListTable = ref(null)

  // Table Handlers
  const CompanyTableColumns = [
    { key: 'Name', sortable: false },
    { key: 'Address', sortable: false },
    { key: 'PostCode', sortable: false },
    { key: 'City', sortable: false },
    { key: 'Package', sortable: false },
    { key: 'CreateAt', sortable: false },
    { key: 'Actions' },
  ]
  const perPage = ref(PER_PAGE)
  const totalCompanies = ref(0)
  const allCompanies = ref([])
  const originalAllCompanies = ref([])
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const onboardedFilter = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refCompanyListTable.value ? refCompanyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCompanies.value,
    }
  })

  const filteredData = () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const data = allCompanies.value.filter(
      company => (company.companyname.toLowerCase().includes(queryLowered)),

    )
    return data
  }

  const filteredOnboardedData = data => {
    const searcchData = data.filter(
      company => (company.onboarded === 1),

    )
    return searcchData
  }

  const refetchData = () => {
    refCompanyListTable.value.refresh()
  }

  watch([currentPage, onboardedFilter, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCompanies = (ctx, callback) => {
    let data = {}
    if (allCompanies.value.length === 0) {
      store.dispatch('app-game/fetchCompaniesAll')
        .then(response => {
          allCompanies.value = response.data
          originalAllCompanies.value = response.data
          totalCompanies.value = allCompanies.value.length
          data = paginateArray(allCompanies.value, perPage.value, 1)
          callback(data)
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      if (onboardedFilter.value) {
        allCompanies.value = JSON.parse(JSON.stringify(filteredOnboardedData(originalAllCompanies.value)))
        data = paginateArray(allCompanies.value, perPage.value, currentPage.value)
        totalCompanies.value = allCompanies.value.length
      } else {
        // eslint-disable-next-line no-self-assign
        allCompanies.value = originalAllCompanies.value
        data = paginateArray(allCompanies.value, perPage.value, currentPage.value)
        totalCompanies.value = allCompanies.value.length
      }

      if (searchQuery.value.length === 0) {
        data = paginateArray(allCompanies.value, perPage.value, currentPage.value)
        totalCompanies.value = allCompanies.value.length
      } else {
        const filterData = filteredData()
        totalCompanies.value = filterData.length
        data = paginateArray(filterData, perPage.value, currentPage.value)
      }
      callback(data)
    }
  }

  return {
    fetchCompanies,
    CompanyTableColumns,
    perPage,
    currentPage,
    totalCompanies,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCompanyListTable,
    refetchData,
    onboardedFilter,
  }
}
