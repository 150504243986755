<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'company-configuration'}"
          >
            <span> {{ $t('ADD_COMPANY') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
          <b-badge
            v-if="onboardedFilter"
            pill
            variant="light-info"
            class="text-capitalize ml-2"
          >
            {{ $t('ONBOARDED') }}
          </b-badge>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  size="17"
                  icon="MoreVerticalIcon"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                v-model="onboardedFilter"
                @click="onboarded(false)"
              >
                {{ $t('ALL') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-model="onboardedFilter"
                @click="onboarded(true)"
              >
                {{ $t('ONBOARDED') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refCompanyListTable"
      :items="fetchCompanies"
      responsive
      :fields="CompanyTableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Company Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.companyname)"
              variant="light-success"
              :to="{ name: 'company-view', params: { id: data.item.companyID } }"
            />
          </template>
          <b-link
            :to="{ name: 'company-view', params: { id: data.item.companyID } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.companyname }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Address -->
      <template #cell(Address)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="data.item.address_line_1"
            icon="MapPinIcon"
            size="18"
            class="mr-50 text-primary"
          />
          <span class="align-text-top text-capitalize">{{ data.item.address_line_1 }} {{ data.item.address_line_2 }}</span>
        </div>
      </template>

      <!-- Column: Post Code -->
      <template #cell(PostCode)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.postcode }}
          </span>
        </b-media>
      </template>

      <!-- Column: City -->
      <template #cell(City)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.city }}
          </span>
        </b-media>
      </template>

      <!-- Column: State -->
      <template #cell(CreateAt)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.created_timestamp) }}
          </span>
        </b-media>
      </template>
      <!-- Column: Package -->
      <template #cell(Package)="data">
        <b-badge
          pill
          :variant="`light-${resolvePackage(data.item.package_id).variant}`"
          class="text-capitalize"
        >
          {{ resolvePackage(data.item.package_id).name }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'company-configuration-id', params: { id: data.item.companyID }})"
          />
          <b-tooltip
            title="Edit Company"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'company-view', params: { id: data.item.companyID }})"
          />
          <b-tooltip
            title="Preview Company"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCompanies"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton,
  BTable, BMedia, BDropdown, BDropdownItem,
  BPagination, BTooltip, BLink, BAvatar, BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import companyStore from '@/store/company/companyStore'
import useCompanyList from './useCompanyList'
import { resolvePackage } from '@/constants/utils'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BTooltip,
    vSelect,
    BLink,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  mixins: [mixinDate],
  setup() {
    const { APP_STORE_MODULE } = constants

    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })

    const {
      fetchCompanies,
      CompanyTableColumns,
      perPage,
      currentPage,
      totalCompanies,
      onboardedFilter,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCompanyListTable,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useCompanyList()

    return {
      fetchCompanies,
      CompanyTableColumns,
      perPage,
      currentPage,
      totalCompanies,
      onboardedFilter,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCompanyListTable,
      refetchData,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      resolvePackage,
    }
  },
  methods: {
    onboarded(value) {
      this.onboardedFilter = value
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
